import { cn } from "@/lib/utils";
import { Link, useMatches } from "@tanstack/react-router";
import { useAtomValue } from "jotai";
import { j_currentTeam } from "@/state";
import { Layout, Palette, FileText, Globe } from "lucide-react";

export function HomeNavigation() {
    const currentTeam = useAtomValue(j_currentTeam);
    const matches = useMatches();
    const currentPath = matches[matches.length - 1].pathname;

    const links = [
        { 
            href: `/t/${currentTeam?.slug}`, 
            label: "Playgrounds", 
            exact: true,
            icon: Layout 
        },
        { 
            href: `/t/${currentTeam?.slug}/brands`, 
            label: "Brands",
            icon: Palette 
        },
        { 
            href: `/t/${currentTeam?.slug}/pages`, 
            label: "Pages",
            icon: FileText 
        },
        { 
            href: `/t/${currentTeam?.slug}/pages/deployed`, 
            label: "Deployed",
            icon: Globe
        },
    ];

    return (
        <nav className="space-y-1">
            {links.map((link) => (
                <Link
                    key={link.href}
                    to={link.href}
                    className={cn(
                        "flex items-center px-3 py-2 text-sm font-medium rounded-md",
                        link.href.split('/').length > 4 && "ml-8",
                        (link.exact ? currentPath === link.href : currentPath.startsWith(link.href))
                            ? "bg-primary/10 text-primary"
                            : "text-muted-foreground hover:bg-muted"
                    )}
                >
                    <link.icon className="w-4 h-4 mr-3 shrink-0" />
                    {link.label}
                </Link>
            ))}
        </nav>
    );
} 