import { j_currentTeamSlug } from "@/state";
import { useAtomValue, atom } from "jotai";
import { atomFamily, } from "jotai/utils";
import { ofetch } from "ofetch";

export interface ResourceSearchResult {
  id: string;
  name: string;
  playground: string;
  generator_tool: string;
  preview_description: string;
}

const resourcesAtom = atomFamily(
  (tools: string) => 
    atom(async (get) => {
      const currentTeamSlug = get(j_currentTeamSlug);
      if (!currentTeamSlug) return [];
      const data = await ofetch<ResourceSearchResult[]>('/api/search', {
        params: {
          team: currentTeamSlug,
          generatorTool: tools
        }
      });
      
      return data;
    })
);

export function useFilteredResources(generatorTools: string | string[]) {
  const tools = Array.isArray(generatorTools) ? generatorTools.join(",") : generatorTools
  const data = useAtomValue(resourcesAtom(tools));
  return { data };
}