import { Suspense } from "react";
import { useResource, useSelectOutputTab } from "../state";

const ResourceLabel = ({resourceId}: {resourceId: string}) => {
    const resource = useResource(resourceId);
    const selectTab = useSelectOutputTab();
    return <span className="text-orange-400 underline cursor-pointer" onClick={() => selectTab(resourceId)}>#{resource.friendlyName} (#{resource.id})</span>
  }
  
  export const LabelWithResources: React.FC<{
    value: string;
    className?: string;
  }> = ({ value, className }) => {
    const l = value.matchAll(/^\$\$resource\(\s*'(?<resource>[^']+)'\s*(,\s*'(?<friendly_name>[^']+)'\s*)?\)/gs)
    let index = 0;
    const elems = []
    for (const match of l){
      elems.push(value.slice(index, match.index))
      if (match.groups?.resource){
        elems.push(
          <Suspense fallback={<span className="text-orange-400">#{match.groups?.resource}</span>}>
          <ResourceLabel resourceId={match.groups?.resource} />
        </Suspense>
      )}
      index = match.index + match[0].length;
    }
    elems.push(value.slice(index))
    return <span className={className}>
      {elems}
    </span>
  };