import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { createRoute, useRouter } from "@tanstack/react-router";
import { PlusIcon, TrashIcon } from "lucide-react";
import { ofetch } from "ofetch";
import { useState } from "react";
import { settingsRoute } from "..";

interface ApiKey {
  id: string;
  name: string;
  createdAt: string;
}

export const settingsApiKeysRoute = createRoute({
  getParentRoute: () => settingsRoute,
  path: "/api-keys",
  loader: async () => {
    const keys = await ofetch<ApiKey[]>("/api/user/api-keys");
    return { keys };
  },
  component: function ApiKeys() {
    const { keys } = settingsApiKeysRoute.useLoaderData();
    const [isOpen, setIsOpen] = useState(false);
    const [newKeyName, setNewKeyName] = useState("");
    const [newKey, setNewKey] = useState<string>();
    const router = useRouter();

    const createKey = async () => {
      const result = await ofetch<ApiKey & { key: string }>("/api/user/api-keys", {
        method: "POST",
        body: { name: newKeyName },
      });
      setNewKey(result.key);
      setNewKeyName("");
      await router.invalidate();
    };

    const deleteKey = async (keyId: string) => {
      await ofetch(`/api/user/api-keys/${keyId}`, {
        method: "DELETE",
      });
      await router.invalidate();
    };

    return (
      <div className="relative">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-semibold">API Keys</h1>
          <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogTrigger asChild>
              <Button>
                <PlusIcon className="h-4 w-4 mr-2" />
                Create API Key
              </Button>
            </DialogTrigger>
            <DialogContent className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-50">
              <DialogHeader>
                <DialogTitle>{newKey ? "Save Your API Key" : "Create New API Key"}</DialogTitle>
              </DialogHeader>
              {newKey ? (
                <div className="space-y-4">
                  <p className="text-sm text-gray-500">
                    Make sure to copy your API key now. You won't be able to see it again!
                  </p>
                  <Input readOnly value={newKey} />
                  <Button
                    onClick={() => {
                      setIsOpen(false);
                      setNewKey(undefined);
                    }}
                  >
                    Done
                  </Button>
                </div>
              ) : (
                <div className="space-y-4">
                  <Input
                    placeholder="Key Name"
                    value={newKeyName}
                    onChange={e => setNewKeyName(e.target.value)}
                  />
                  <Button onClick={createKey} disabled={!newKeyName}>
                    Create
                  </Button>
                </div>
              )}
            </DialogContent>
          </Dialog>
        </div>

        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Name</TableHead>
              <TableHead>ID</TableHead>
              <TableHead>Created</TableHead>
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {keys.map(key => (
              <TableRow key={key.id}>
                <TableCell>{key.name}</TableCell>
                <TableCell>{key.id}</TableCell>
                <TableCell>{new Date(key.createdAt).toLocaleDateString()}</TableCell>
                <TableCell>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => deleteKey(key.id)}
                  >
                    <TrashIcon className="h-4 w-4" />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  },
}); 