import { createRoute, Link, Outlet } from "@tanstack/react-router";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { ChevronLeftIcon, ChevronRightIcon, UsersIcon, HomeIcon } from "lucide-react";
import { teamRoute } from "@/app";
import { teamSettingsMembersRoute } from "./members";

export const teamSettingsRoute = createRoute({
    getParentRoute: () => teamRoute,
    path: "/settings",
    component: function TeamSettings() {
        const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
        const menuItems = [
            { name: "General", icon: HomeIcon, path: "." },
            { name: "Members", icon: UsersIcon, path: "members" }
        ];

        return (
            <div className="flex min-h-screen">
                {/* Sidebar */}
                <aside className={`bg-gray-900 text-white transition-all duration-300 ease-in-out ${sidebarCollapsed ? "w-24" : "w-64"}`}>
                    <div className="p-4 flex justify-between items-center">
                        {!sidebarCollapsed && <h2 className="text-xl font-semibold">Team Settings</h2>}
                        <Button 
                            variant="ghost" 
                            size="icon" 
                            onClick={() => setSidebarCollapsed(!sidebarCollapsed)} 
                            className="text-white hover:bg-gray-700"
                        >
                            {sidebarCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </Button>
                    </div>
                    <nav className="mt-4 px-4">
                        {menuItems.map(item => (
                            <Link 
                                key={item.name} 
                                to={`/t/$teamId/settings/${item.path}`}
                                params={{ teamId: teamRoute.useParams().teamId }}
                                className="block mb-2"
                            >
                                <Button 
                                    variant="secondary" 
                                    className="w-full text-left p-4 flex items-center"
                                >
                                    <item.icon className="h-5 w-5 mr-2" />
                                    {!sidebarCollapsed && <span>{item.name}</span>}
                                </Button>
                            </Link>
                        ))}
                    </nav>
                </aside>

                {/* Main content */}
                <main className="flex-1 p-8">
                    <div className="max-w-4xl mx-auto">
                        <Outlet />
                    </div>
                </main>
            </div>
        );
    },
}); 

teamSettingsRoute.addChildren([
    teamSettingsMembersRoute
]);