/// <reference types="react/canary" />

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { AlertDialogPortal } from "@radix-ui/react-alert-dialog";
import { createRoute, Link, redirect, type AnyRoute, useRouter,  Outlet } from "@tanstack/react-router";
import { Car, PlusCircleIcon, Trash, Trash2, TrashIcon } from "lucide-react";
import { ofetch } from "ofetch";
import { useMemo, Suspense } from "react";
import type { Playground } from "shared/data/playground";
import type { ClientToolDef } from "shared/tool";
import { useOptimistic } from "react";
import { useAtomValue } from "jotai";
import { j_currentTeam } from "@/state";
import { HomeNavigation } from "./HomeNavigation";
import { BrandsView } from "./BrandsView";
import { PagesView } from "./PagesView";
import { DeployedPagesView } from "./DeployedPagesView";

export const homeRoute = <TParentRoute extends AnyRoute = AnyRoute>(parent: TParentRoute) => {
  const r = createRoute({
    getParentRoute: () => parent,
    id: "hone-layout",
    component: () => {
      return (
        <div className="flex h-full">
          <div className="w-64 p-4 border-r">
            <HomeNavigation />
          </div>
          <div className="flex-1 max-w-[1800px] mx-auto p-6 space-y-12">
            <Suspense fallback={<div>Loading...</div>}>
              <Outlet />
            </Suspense>
          </div>
        </div>
      );
    },
  });

  const indexRoute = createRoute({
    getParentRoute: () => r,
    path: "/",
    loader: async ({ context, params }) => {
      const { playgrounds } = await ofetch<{ playgrounds: Playground[] }>(`/api/playgrounds?team=${params.teamId}`);
      const { tools } = await ofetch<{ tools: ClientToolDef[] }>("/api/tools");
      return { playgrounds, tools };
    },
    component: () => {
      const { playgrounds, tools } = indexRoute.useLoaderData();
      return <PlaygroundsHome playgrounds={playgrounds} tools={tools} />;
    }
  });

  const brandsRoute = createRoute({
    getParentRoute: () => r,
    path: "/brands",
    component: BrandsView,
  });

  const pagesRoute = createRoute({
    getParentRoute: () => r,
    path: "/pages",
    component: PagesView,
  });

  const deployedPagesRoute = createRoute({
    getParentRoute: () => r,
    path: "/pages/deployed",
    component: DeployedPagesView,
  });

  r.addChildren([indexRoute, brandsRoute, pagesRoute, deployedPagesRoute]);

  return r;
};

export const PlaygroundsHome: React.FC<{
  playgrounds: Playground[];
  tools: ClientToolDef[];
}> = ({ playgrounds: _playgrounds, tools }) => {
  const newPlaygroundTools = useMemo(() => tools.filter(t => t.annotations["display-create-playground"]), [tools]);
  const [playgrounds, playgroundsOptUpdate] = useOptimistic(_playgrounds, (playgrounds, action: (p: Playground[]) => Playground[]) => {
    return action(playgrounds);
  });
  const currentTeam = useAtomValue(j_currentTeam);
  const router = useRouter();
  return (
    <div className="space-y-12">
      <div>
        <h1 className="text-3xl font-bold mb-2  dark:text-gray-100">New Playground</h1>
        <p className="text-muted-foreground mb-6">Start with a template or create an empty playground</p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 max-w-[1400px] gap-6">
          <>
            {newPlaygroundTools.map(t => (
              <Link to={`/t/${currentTeam?.slug}/new-playground?tool=${t.name}`} key={t.name}>
                <Card className="cursor-pointer group hover:border-primary/50 hover:shadow-md transition-all duration-200 h-full bg-card/50">
                  <CardHeader className="h-28 space-y-1 pb-2">
                    <div className="flex justify-between items-start">
                      <CardTitle className="text-lg leading-tight group-hover:text-primary transition-colors">
                        {t.annotations["display-name"] ?? t.name}
                      </CardTitle>
                      <PlusCircleIcon size={24} className="text-muted-foreground group-hover:text-primary transition-colors" />
                    </div>
                    <CardDescription className="text-sm line-clamp-2">
                      {t.annotations["description"] ?? t.description}
                    </CardDescription>
                  </CardHeader>
                </Card>
              </Link>
            ))}
            <Link to={`/t/${currentTeam?.slug}/new-playground`}>
              <Card className="cursor-pointer group hover:border-primary/50 hover:shadow-md transition-all duration-200 h-full border-dashed bg-muted/50">
                <CardHeader className="h-28 space-y-1 pb-2">
                  <div className="flex justify-between items-start">
                    <CardTitle className="text-lg leading-tight group-hover:text-primary transition-colors">
                      Empty playground
                    </CardTitle>
                    <PlusCircleIcon size={24} className="text-muted-foreground group-hover:text-primary transition-colors" />
                  </div>
                  <CardDescription className="text-sm">Start with a blank canvas</CardDescription>
                </CardHeader>
              </Card>
            </Link>
          </>
        </div>
      </div>

      <div>
        <h1 className="text-3xl font-bold mb-2 text-gray-800 dark:text-gray-100">My Playgrounds</h1>
        <p className="text-muted-foreground mb-6">Your existing playground environments</p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5  gap-6">
          {playgrounds.map(p => (
            <Link
              to="/p/$playgroundId"
              onClick={e => {
                const target = e.target as Element;
                if (target && target.closest("[data-prevent-nav]")) {
                  e.preventDefault();
                }
              }}
              key={p.id}
              params={{ playgroundId: p.id }}
            >
              <Card className="cursor-pointer group hover:border-primary/50 hover:shadow-md transition-all duration-200 h-full bg-card/50">
                <CardHeader className="h-28 pb-2">
                  <div className="flex justify-between items-start">
                    <CardTitle className="text-lg leading-tight group-hover:text-primary transition-colors line-clamp-2">
                      {p.name}
                    </CardTitle>
                    <DeletePlaygroundButton
                      playgroundId={p.id}
                      onDelete={async () => {
                        await ofetch(`/api/playgrounds/${p.id}`, {
                          method: "DELETE",
                        });
                        await router.invalidate();
                      }}
                    />
                  </div>
                </CardHeader>
                <CardFooter className="pt-2 border-t">
                  <CardDescription className="text-xs text-muted-foreground flex items-center gap-2">
                    <span className="w-2 h-2 rounded-full bg-primary/60" />
                    Created {new Date(p.createdAt ?? new Date().getTime()).toLocaleDateString()}
                  </CardDescription>
                </CardFooter>
              </Card>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

const DeletePlaygroundButton: React.FC<{
  playgroundId: string;
  onDelete: () => void;
}> = ({ playgroundId, onDelete }) => {
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild data-prevent-nav>
        <Button 
          size="icon" 
          variant="ghost" 
          className="h-8 w-8 hover:bg-destructive/10 hover:text-destructive opacity-0 group-hover:opacity-100 transition-opacity"
        >
          <Trash2 className="h-4 w-4" />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogPortal>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete your playground and all resources inside.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel data-prevent-nav>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={() => onDelete()} data-prevent-nav>
              Continue
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogPortal>
    </AlertDialog>
  );
};
