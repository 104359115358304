import { useSession } from "@hono/auth-js/react";
import { createRoute, Link, Outlet } from "@tanstack/react-router";
import { useAtomValue } from "jotai";
import { j_currentTeam } from "./state";
import { Avatar, AvatarImage } from "./components/ui/avatar";
import { rootRoute } from "./root-route";
import { TeamSwitcher } from "./components/TeamSwitcher";
import { SearchCommand } from "./components/SearchCommand";
import { ThemeToggle } from "./components/theme-toggle";
import { Logo } from "./components/Logo";
import { Popover, PopoverContent, PopoverTrigger } from "./components/ui/popover";
import { Button } from "./components/ui/button";
import { SettingsIcon } from "lucide-react";
import { Toaster } from "@/components/ui/toaster";

export const Layout = () => {
  const user = useSession().data?.user;
  const currentTeam = useAtomValue(j_currentTeam);
  
  return (
    <div className="flex flex-col fixed top-0 left-0 right-0 bottom-0">
      <div className="h-10 flex items-center justify-between px-4 bg-background border-b">
        <Link
          to={currentTeam ? `/t/${currentTeam.slug}` : "/"}
          className="flex items-center gap-2 text-sm font-medium text-muted-foreground hover:text-foreground transition-colors"
        >
          <Logo />
          <span>Livecycle AI</span>
        </Link>
        <div className="flex items-center gap-3">
          <ThemeToggle />
          <div className="h-6 w-[1px] bg-border" />
          <TeamSwitcher />
          {currentTeam && (
            <>
              <Link
                to={`/t/${currentTeam.slug}/settings`}
                className="flex items-center text-muted-foreground hover:text-foreground transition-colors"
              >
                <Button variant="ghost" size="icon" className="h-8 w-8">
                  <SettingsIcon className="h-4 w-4" />
                </Button>
              </Link>
            </>
          )}
          <div className="h-6 w-[1px] bg-border" />
          <Popover>
            <PopoverTrigger asChild>
              <Button variant="ghost" size="sm" className="flex items-center gap-2">
                <div className="text-sm text-muted-foreground">
                  {user?.name}
                </div>
                <Avatar className="h-6 w-6">
                  <AvatarImage src={user?.image ?? ""} />
                </Avatar>
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-48" align="end">
              <div className="flex flex-col space-y-1">
                <Link
                  to="/settings/api-keys"
                  className="flex items-center gap-2 px-2 py-1.5 text-sm rounded-md hover:bg-accent"
                >
                  <SettingsIcon className="h-4 w-4" />
                  Settings
                </Link>
                <div className="h-[1px] bg-border my-1" />
                <a
                  href="/api/auth/signout"
                  className="flex items-center px-2 py-1.5 text-sm rounded-md hover:bg-accent text-muted-foreground hover:text-foreground transition-colors"
                >
                  Sign out
                </a>
              </div>
            </PopoverContent>
          </Popover>
        </div>
      </div>
      <div className="flex-1 overflow-auto">
        <SearchCommand />
        <Outlet />
      </div>
      <Toaster />
    </div>
  );
};

export const layoutRoute = createRoute({
  getParentRoute: () => rootRoute,
  id: "layout",
  component: Layout,
});