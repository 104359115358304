import { Suspense } from "react";
import { useFilteredResources } from "./hooks";
import { ResourceCard } from "./ResourceCard";
import { Skeleton } from "@/components/ui/skeleton";

const PagesList = () => {
    const { data: resources } = useFilteredResources(['deploy-page']);
    return <div className="flex flex-col gap-4">
        {resources.map(resource => (
            <ResourceCard key={resource.id} resource={resource} />
        ))}
    </div>
}

export function DeployedPagesView() {
    return (
        <div className="space-y-6">
            <div>
                <h1 className="text-3xl font-bold mb-2 text-gray-800 dark:text-gray-100">Deployed Pages</h1>
                <p className="text-muted-foreground mb-6">Pages that have been deployed</p>
            </div>
            <Suspense fallback={
                <div className="flex flex-col gap-4">
                    {Array.from({ length: 3 }).map((_, i) => (
                        <Skeleton key={i} className="h-32 w-full rounded-lg" />
                    ))}
                </div>
            }>
                <PagesList/>
            </Suspense>
        </div>
    );
} 