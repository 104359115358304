import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import type React from "react";
import { useRef, useState, useEffect, useMemo, forwardRef, useCallback } from "react";
import type { UIResource } from "shared/data/resource";
import { useResource, useSelectOutputTab } from "../state";
import { match } from "ts-pattern";
import { jsonSchemaToZod } from "json-schema-to-zod";
import { z, type SomeZodObject } from "zod";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "@/components/ui/input";
import { sentenceCase } from "change-case";
import { Button } from "@/components/ui/button";
import { useGetSuspendCauseResource, useResourceWSApi } from "../hooks";
import EasyMDE from "../../../components/EasyMDE";
import "easymde/dist/easymde.min.css";
import "../../../components/easymde-dark.css";
import { MarkdownWithOriginal } from "@/components/Markdown";
import { SelectElementRemixPanel, SelectVariationRemixPanel, type SelectVariationToApply } from "./interactive-remix";
import { getSuspendTraceData } from "@/lib/suspend-utils";
import { WebsitePanel } from "./website";
import { getResourceOutput } from "@/lib/resource-utils";

type SuspendWidget = SplitChoiceWidget;

type SplitChoiceWidget = {
  type: "widget";
  widgetType: "split-choice";
  schema: string;
  instructions: string;
  args: unknown[];
};

type SuspendPanel = EditAndConfirmPanel | SelectElementRemixPanel | SelectVariationToApply;

type EditAndConfirmPanel = {
  type: "editable";
  panelType: "edit-and-confirm";
  data: string;
};

type SuspendData = SuspendWidget | SuspendPanel;

const isWidget = (args: unknown): args is SuspendWidget => {
  return args !== null && typeof args === "object" && "type" in args && args.type === "widget";
};

const isEditable = (args: unknown): args is SuspendWidget => {
  return args !== null && typeof args === "object" && "type" in args && args.type === "editable";
};

export const EditAndConfirmPanel = ({ resource }: { resource: UIResource }) => {
  const suspendTrace = getSuspendTraceData(resource);
  if (!suspendTrace) {
    return null;
  }
  const resourceApi = useResourceWSApi();
  if (suspendTrace.args[0].type !== "editable") {
    throw new Error("Invalid suspend trace");
  }
  const [value, setValue] = useState(suspendTrace.args[0].data);
  const options = useMemo(
    () => ({
      maxHeight: "none",
      lineNumbers: false,
      status: false,
      toolbar: ["bold", "italic", "heading", "|", "quote", "unordered-list", "ordered-list", "|", "link", "image"],
      spellChecker: false,
      autofocus: true,
    }),
    [],
  );

  return (
    <div className="flex flex-col h-full relative">
      <div className="flex-1 min-h-0 overflow-auto custom-scrollbar">
        <div className="h-full [&_.editor-toolbar]:sticky [&_.editor-toolbar]:top-0 [&_.editor-toolbar]:z-10 [&_.editor-toolbar]:bg-slate-950">
          <EasyMDE 
            className="h-full [&_.CodeMirror-scroll]:custom-scrollbar"
            value={value} 
            onChange={setValue} 
            options={options}
          />
        </div>
      </div>
      <div className="flex flex-row p-2 bg-slate-600 rounded-md items-center mt-2">
        <div className="text-lg flex-1">You can edit the text and make changes if needed, then click approve to continue</div>
        <div className="justify-end self-end">
          <Button
            onClick={async () => {
              await resourceApi.release(
                {
                  rootResourceId: suspendTrace.rootContext,
                  triggeringResourceId: suspendTrace.contextName,
                },
                {
                  args: suspendTrace.args,
                  result: {
                    data: value,
                    type: "edit",
                  },
                },
              );
            }}
            variant="secondary"
          >
            APPROVE
          </Button>
        </div>
      </div>
    </div>
  );
};

export const SuspendResourcePanel = ({ resource }: { resource: UIResource }) => {
  if (resource.status === "done" || resource.status === "error" || resource.status === "generating") {
    if (getResourceOutput(resource)?.startsWith("<!DOCTYPE")) {
      return <WebsitePanel key={resource.id} resourceRef={resource.id} />;
    }
    return (
      <MarkdownWithOriginal key={resource.id} className="">
        {resource.output.data}
      </MarkdownWithOriginal>
    );
  }
  const suspendTrace = getSuspendTraceData<SuspendData>(resource);
  if (!suspendTrace) {
    return "No suspend trace found for resource";
  }

  if (suspendTrace.args[0].type === "widget") {
    return <SuspendDialog key={suspendTrace.index} resource={resource} />;
  }

  if (suspendTrace.args[0].type === "editable") {
    if (suspendTrace.args[0].panelType === "edit-and-confirm") {
      return <EditAndConfirmPanel key={suspendTrace.index} resource={resource} />;
    }
    if (suspendTrace.args[0].panelType === "ir-select-element-remix") {
      return <SelectElementRemixPanel key={suspendTrace.index} resource={resource} trace={suspendTrace} />;
    }
    if (suspendTrace.args[0].panelType === "ir-select-variation-to-apply") {
      return <SelectVariationRemixPanel key={suspendTrace.index} resource={resource} trace={suspendTrace} />;
    }
  }

  return "Suspend resource";
};

export const SuspendWidget = ({ resource }: { resource: UIResource }) => {
  const SuspendResource = useGetSuspendCauseResource(resource.id);
  if (!SuspendResource) {
    return "No suspend resource";
  }
  const suspendTrace = getSuspendTraceData<SuspendData>(resource);
  if (SuspendResource.generator?.outputType === "suspender") {
    if (!isWidget(suspendTrace?.data?.args[0])) {
      return null;
    }
  }
  return <SuspendDialog key={suspendTrace?.index} resource={SuspendResource} />;
};

export const SuspendDialog = ({ resource }: { resource: UIResource }) => {
  const suspendTrace = getSuspendTraceData<SuspendWidget>(resource);
  const targetEl = useRef<HTMLElement>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const resourceApi = useResourceWSApi();
  useEffect(() => {
    if (targetEl.current) {
      setOpenDialog(true);
    }
  }, []);
  const widgetSpec = suspendTrace?.args[0];

  const onSubmit = useCallback(
    async (v: unknown) => {
      if (!suspendTrace) {
        throw new Error("No suspend trace");
      }
      await resourceApi.release(
        {
          rootResourceId: suspendTrace.rootContext,
          triggeringResourceId: suspendTrace.contextName,
        },
        { args: suspendTrace.args, result: v },
      );
    },
    [resourceApi, suspendTrace],
  );

  if (!suspendTrace) {
    return "No suspend trace";
  }
  if (!isWidget(widgetSpec)) {
    return "Invalid widget spec";
  }

  //@ts-ignore
  return (
    <div ref={targetEl} className="absolute top-0 right-0 left-0 bottom-0">
      {targetEl.current && (
        <Dialog open={openDialog} modal={false}>
          <DialogContent className="min-w-[80%]" portal={targetEl.current ?? undefined}>
            <DialogHeader>
              <DialogTitle>{resource.friendlyName ?? resource.id}</DialogTitle>
            </DialogHeader>
            <DialogDescription>{widgetSpec.instructions}</DialogDescription>
            {match(widgetSpec.widgetType)
              .with("split-choice", () => <SplitChoicesWidget spec={widgetSpec} onSubmit={onSubmit} />)
              .exhaustive()}
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

const SplitChoicesWidget = ({ spec, onSubmit }: { spec: SuspendWidget; onSubmit: (r: unknown) => Promise<void> }) => {
  const [selected, setSelected] = useState(-1);
  const schema: SomeZodObject = useMemo(() => new Function("z", `return (${jsonSchemaToZod(JSON.parse(spec.schema), { module: "none" })})`)(z), [spec.schema]);
  const formRef = useRef<HTMLFormElement>(null);
  return (
    <div className="flex flex-col gap-4">
      <div
        className="grid p-4 gap-4"
        style={{
          gridTemplateColumns: `repeat(${spec.args.length}, 1fr)`,
        }}
      >
        {spec.args.map((value, i) => {
          return (
            <WidgetChoice
              ref={i === selected ? formRef : undefined}
              argsType={schema}
              onClick={() => setSelected(i)}
              value={value}
              key={i}
              editable={i === selected}
              onSubmit={onSubmit}
            />
          );
        })}
      </div>
      <Button
        variant="secondary"
        className="self-center"
        onClick={() => {
          if (formRef.current) {
            formRef.current.requestSubmit();
          }
        }}
      >
        Continue
      </Button>
    </div>
  );
};

const ResourceField = ({ resourceId }: { resourceId: string }) => {
  const resource = useResource(resourceId);
  const selectOutputTab = useSelectOutputTab();
  return <Input onClick={() => selectOutputTab(resourceId)} disabled={true} value={`#${resource.friendlyName}`} />;
};

const WidgetChoice = forwardRef(
  <T extends SomeZodObject>(
    props: {
      argsType: T;
      value: z.TypeOf<T>;
      editable: boolean;
      onClick: () => void;
      onSubmit: (v: z.TypeOf<T>) => Promise<void>;
    },
    ref: React.ForwardedRef<HTMLFormElement>,
  ) => {
    const formSchema = props.argsType;
    const form = useForm<z.infer<typeof formSchema>>({
      resolver: zodResolver(formSchema),
      defaultValues: props.value,
    });

    return (
      <div
        onClick={props.onClick}
        className={`p-4 border-2 rounded-lg ${!props.editable ? "border-gray-600 hover:border-gray-100" : "border-gray-50"} cursor-pointer`}
      >
        <div className={!props.editable ? "pointer-events-none" : ""}>
          <Form {...form}>
            <form ref={ref} onSubmit={form.handleSubmit(props.onSubmit)} className="flex flex-col gap-2">
              {Object.entries(formSchema.shape).map(([key, zField]) => {
                return (
                  <FormField
                    control={form.control}
                    key={key}
                    name={key}
                    render={({ field }) => (
                      <FormItem className="flex flex-col justify-center relative">
                        <FormLabel className="absolute top-0 bg-slate-950 white left-4 px-2 py-0 text-xs z-50">{sentenceCase(key)}</FormLabel>
                        <FormControl>
                          {typeof field.value === "object" && "$$resourceId" in field.value ? (
                            <ResourceField resourceId={field.value["$$resourceId"]} />
                          ) : (
                            <Input
                              {...field}
                              {...(zField instanceof z.ZodNumber
                                ? {
                                    type: "number",
                                  }
                                : {})}
                              disabled={!props.editable}
                              onChange={e => field.onChange(zField instanceof z.ZodNumber ? e.target.valueAsNumber : e.target.value)}
                            />
                          )}
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                );
              })}
            </form>
          </Form>
        </div>
      </div>
    );
  },
);
