import { Button } from "@/components/ui/button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { createRoute, useRouter } from "@tanstack/react-router";
import { ofetch } from "ofetch";
import { PlusIcon, TrashIcon } from "lucide-react";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger, DialogPortal } from "@/components/ui/dialog";
import { useState } from "react";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { teamSettingsRoute } from ".";
import { useSession } from "@hono/auth-js/react";
import { useToast } from "@/components/hooks/use-toast";

type TeamMember = {
    userId: string;
    name: string;
    email: string;
    role: "admin" | "member";
};

export const teamSettingsMembersRoute = createRoute({
    getParentRoute: () => teamSettingsRoute,
    path: "/members",
    loader: async ({ params }) => {
        const response = await ofetch<{ members: TeamMember[] }>(`/api/teams/${params.teamId}/members`);
        return response;
    },
    component: function TeamMembers() {
        const { members } = teamSettingsMembersRoute.useLoaderData();
        const router = useRouter();
        const { teamId } = teamSettingsRoute.useParams();
        const [isAddingMember, setIsAddingMember] = useState(false);
        const [newMemberEmail, setNewMemberEmail] = useState("");
        const [newMemberRole, setNewMemberRole] = useState<"admin" | "member">("member");
        const { toast } = useToast();

        const addMember = async () => {
            try {
                await ofetch(`/api/teams/${teamId}/members`, {
                    method: "POST",
                    body: JSON.stringify({
                        email: newMemberEmail,
                        role: newMemberRole
                    })
                });
                setIsAddingMember(false);
                setNewMemberEmail("");
                setNewMemberRole("member");
                await router.invalidate();
                toast({
                    title: "Success",
                    description: "Team member added successfully",
                });
            } catch (error: any) {
                toast({
                    variant: "destructive",
                    title: "Error adding member",
                    description: error.data?.error || error.message || "Failed to add member",
                });
            }
        };

        const removeMember = async (userId: string) => {
            try {
                await ofetch(`/api/teams/${teamId}/members/${userId}`, {
                    method: "DELETE"
                });
                await router.invalidate();
                toast({
                    title: "Success",
                    description: "Team member removed successfully",
                });
            } catch (error: any) {
                toast({
                    variant: "destructive",
                    title: "Error removing member",
                    description: error.data?.error || error.message || "Failed to remove member",
                });
            }
        };

        return (
            <div>
                <div className="flex justify-between items-center mb-6">
                    <h1 className="text-3xl font-semibold">Team Members</h1>
                    <Dialog open={isAddingMember} onOpenChange={setIsAddingMember}>
                        <DialogTrigger asChild>
                            <Button>
                                <PlusIcon className="h-4 w-4 mr-2" />
                                Add Member
                            </Button>
                        </DialogTrigger>
                        <DialogPortal>
                            <DialogContent className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                                <DialogHeader>
                                    <DialogTitle>Add Team Member</DialogTitle>
                                </DialogHeader>
                                <form 
                                    onSubmit={async (e) => {
                                        e.preventDefault();
                                        await addMember();
                                    }}
                                    className="space-y-4 mt-4"
                                >
                                    <Input
                                        placeholder="Email address"
                                        type="email"
                                        value={newMemberEmail}
                                        onChange={(e) => setNewMemberEmail(e.target.value)}
                                        required
                                    />
                                    <Select 
                                        value={newMemberRole} 
                                        onValueChange={(value: "admin" | "member") => setNewMemberRole(value)}
                                        required
                                    >
                                        <SelectTrigger>
                                            <SelectValue placeholder="Select role" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectItem value="member">Member</SelectItem>
                                            <SelectItem value="admin">Admin</SelectItem>
                                        </SelectContent>
                                    </Select>
                                    <Button type="submit" className="w-full">
                                        Add Member
                                    </Button>
                                </form>
                            </DialogContent>
                        </DialogPortal>
                    </Dialog>
                </div>

                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead>Name</TableHead>
                            <TableHead>Email</TableHead>
                            <TableHead>Role</TableHead>
                            <TableHead className="w-[100px]">Actions</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {members.map(member => (
                            <TableRow key={member.userId}>
                                <TableCell>{member.name}</TableCell>
                                <TableCell>{member.email}</TableCell>
                                <TableCell className="capitalize">{member.role}</TableCell>
                                <TableCell>
                                    <Button
                                        variant="destructive"
                                        size="icon"
                                        onClick={() => removeMember(member.userId)}
                                    >
                                        <TrashIcon className="h-4 w-4" />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        );
    },
}); 