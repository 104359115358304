import { Card, CardHeader, CardTitle, CardDescription } from "@/components/ui/card";
import { Link } from "@tanstack/react-router";
import type { ResourceSearchResult } from "./hooks";

export function ResourceCard({ resource }: {resource: ResourceSearchResult}) {
    return (
        <Link
            key={resource.id}
            to={`/p/${resource.playground}#outputTab="${resource.id}"`}
            className="block w-full mb-4"
        >
            <Card className="cursor-pointer hover:border-primary/50 hover:shadow-md transition-all duration-200">
                <CardHeader>
                    <CardTitle className="text-lg">{resource.name}</CardTitle>
                    <CardDescription className="line-clamp-2">{resource.preview_description}</CardDescription>
                </CardHeader>
            </Card>
        </Link>
    )
}