import React, { useEffect } from "react";
import { PlaygroundHome } from "./pages/playground";
import {  j_playgroundId, j_remoteResources, j_router, j_selectedOutputTab } from "./pages/playground/state";
import "./globals.css";
import { atom, createStore, Provider, useAtomValue } from "jotai";
import paintWorklet from "./assets/rough-painter.js?url";
import { createRootRoute, createRoute, Outlet, RouterProvider, createRouter, Link, redirect } from "@tanstack/react-router";
import { useSession } from "@hono/auth-js/react";

import "jotai-devtools/styles.css";
import { Card, CardContent, CardHeader, CardTitle } from "./components/ui/card";
import { PostHogProvider, usePostHog } from "posthog-js/react";
import { adminRoute } from "./pages/admin";
import type { User } from "@auth/core/types";
import { homeRoute } from "./pages/home";
import { Avatar, AvatarImage } from "./components/ui/avatar";
import { Skeleton } from "./components/ui/skeleton";
import { newPlaygroundRoute } from "./pages/new-playground";
import { j_connectResourceWS } from "./pages/playground/hooks";
import { ofetch } from "ofetch";
import type { Team } from "shared/data/team";
import { j_currentTeam, j_currentTeamSlug, j_rootStore, j_teams } from "./state";
import type { Playground } from "shared/data/playground";
import { layoutRoute } from "./layout";
import { rootRoute } from "./root-route";
import { newTeamRoute } from "./pages/teams/new";
import { SearchCommand } from "@/components/SearchCommand";
import { settingsRoute } from "./pages/settings";
import { teamSettingsRoute } from "./pages/t/settings";
import { teamSettingsMembersRoute } from "./pages/t/settings/members";
CSS.paintWorklet.addModule(paintWorklet);

export const teamRedirectRoute = createRoute({
  path: "/",
  beforeLoad: async ({ context }) => {
    const user = context.user;
    /*
    if (user === undefined) {
      return false;
    }*/
    if (!user) {
      document.location.href = "/api/auth/signin";
      return null;
    }
    if (!user.isAllowed) {
      return redirect({
        to: "/not-allowed",
        throw: true,
      });
    }

    const teams = await ofetch<Team[]>("/api/teams");
    if (teams.length >= 1) {
      return redirect({ to: `/t/${teams[0].slug}` });
    }
  },
  getParentRoute: () => rootRoute,
});

export const teamRoute = createRoute({
  path: "/t/$teamId",
  beforeLoad: async ({ context, params }) => {
    const user = context.user;
    if (user === undefined) {
      return false;
    }
    if (!user) {
      document.location.href = "/api/auth/signin";
      return null;
    }
    if (!user.isAllowed) {
      return redirect({
        to: "/not-allowed",
        throw: true,
      });
    }
    j_rootStore.set(j_currentTeamSlug, params.teamId);
    return false;
  },
  getParentRoute: () => layoutRoute,
});

teamRoute.addChildren([
  teamSettingsRoute
]);

const notAllowedRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/not-allowed",
  component: () => (
    <div className="flex flex-col w-full my-40">
      <Card className="mx-auto my-auto">
        <CardHeader>
          <CardTitle>Livecycle is still in closed beta</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col gap-4">
            <div>
              You've been added to our waitlist, if you can't wait, you can <a href="mailto:support@livecycle.io">reach us</a>.
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  ),
});

const _homeRoute = homeRoute(teamRoute);


const stores = new Map<string, ReturnType<typeof createStore>>();

const j_playground = atom(async (get)=> {
    return await ofetch<Playground>(`/api/playgrounds/${get(j_playgroundId)}`)
})
const playgroundRoute = createRoute({
  getParentRoute: () => layoutRoute,
  path: "/p/$playgroundId",
  loader: async ({ params, location }) => {
    let store: ReturnType<typeof createStore>;
    if (stores.has(params.playgroundId)) {
      store = stores.get(params.playgroundId)!;
    } else {
      store = createStore();
      stores.set(params.playgroundId, store);
    }
    const id = params.playgroundId;
    store.set(j_playgroundId, id);
    j_rootStore.set(j_playgroundId, id);
    const playground = await store.get(j_playground);
    const teams = await j_rootStore.get(j_teams);
    const currentTeam = teams.find(t=> t.id === playground.team)
    if (currentTeam) {
        j_rootStore.set(j_currentTeamSlug, currentTeam.slug);
        store.set(j_currentTeamSlug, currentTeam.slug);
    }
    let resources = await store.get(j_remoteResources);
    store.set(j_router, router);
    if (location.hash.includes("outputTab")) {
      const outputTab = decodeURIComponent(decodeURI(location.hash.split("outputTab=")[1])).replaceAll(/"/g, "");
      store.set(j_selectedOutputTab, outputTab);
    }
    if (location.search.connect) {
      for (let tries = 0; tries < 4; tries++) {
        if (resources.some(x => x.id === location.search.connect)) {
          console.log("found the resource" + location.search.connect);
          break;
        }
        await new Promise(resolve => setTimeout(resolve, 1000 * Math.pow(2, tries)));
        resources = await store.set(j_remoteResources);
      }
      setTimeout(() => {
        store.set(j_connectResourceWS, location.search.connect);
      }, 1000);
    }
    return { store };
  },
  pendingComponent: () => (
    <div className="flex w-full h-full flex-col p-20">
      <Skeleton className="flex-1 w-full h-full" />
    </div>
  ),
  component: function Playground() {
    const { store } = playgroundRoute.useLoaderData();
    return (
      <Provider store={store}>
        <PlaygroundHome />
      </Provider>
    );
  },
});

const routeTree = rootRoute.addChildren([
  layoutRoute,
  teamRoute,
  teamRedirectRoute,
  _homeRoute,
  newPlaygroundRoute,
  playgroundRoute,
  notAllowedRoute,
  adminRoute,
  newTeamRoute,
  settingsRoute
]);

const router = createRouter({
  routeTree,
  context: { user: undefined! as User | undefined },
});

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

const UserAnalyticsProvider = ({ children }: { children: React.ReactNode }) => {
  const posthog = usePostHog();
  const session = useSession();
  const userSession = session.data?.user;
  useEffect(() => {
    if (!userSession?.email) {
      return;
    }
    posthog.identify(userSession.email, {
      email: userSession.email,
      name: userSession.name,
    });
  }, [userSession?.email]);

  return children;
};

export const App = () => {
  const auth = useSession();
  if (auth.status === "loading") {
    return;
  }
  return (
    <PostHogProvider
      apiKey="phc_6diyp44WAiUJmihs6aqexBb8jPp5TivvPVu02hXlpcC"
      options={{
        api_host: "https://us.i.posthog.com",
        person_profiles: "identified_only",
      }}
    >
      <UserAnalyticsProvider>
        <RouterProvider router={router} context={{ user: auth.data?.user }} />
      </UserAnalyticsProvider>
    </PostHogProvider>
  );
};