import { createRoute, Link, Outlet } from "@tanstack/react-router";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { ChevronLeftIcon, ChevronRightIcon, KeyIcon } from "lucide-react";
import { layoutRoute } from "@/layout";
import { settingsApiKeysRoute } from "./api-keys";

export const settingsRoute = createRoute({
  getParentRoute: () => layoutRoute,
  path: "/settings",
  component: function Settings() {
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    const menuItems = [
      { name: "API Keys", icon: KeyIcon, path: "api-keys" }
    ];

    return (
      <div className="flex h-screen">
        {/* Sidebar */}
        <aside className={`bg-gray-900 text-white transition-all duration-300 ease-in-out ${sidebarCollapsed ? "w-24" : "w-64"}`}>
          <div className="p-4 flex justify-between items-center">
            {!sidebarCollapsed && <h2 className="text-xl font-semibold">Settings</h2>}
            <Button 
              variant="ghost" 
              size="icon" 
              onClick={() => setSidebarCollapsed(!sidebarCollapsed)} 
              className="text-white hover:bg-gray-700"
            >
              {sidebarCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </Button>
          </div>
          <nav className="mt-4 px-4">
            {menuItems.map(item => (
              <Link key={item.name} from={settingsRoute.fullPath} to={`./${item.path}`}>
                <Button variant="secondary" className="w-full text-left p-4 flex items-center">
                  <item.icon className="h-5 w-5 mr-2" />
                  {!sidebarCollapsed && <span>{item.name}</span>}
                </Button>
              </Link>
            ))}
          </nav>
        </aside>

        {/* Main content */}
        <main className="flex-1 p-8 overflow-auto">
          <div className="max-w-4xl mx-auto">
            <Outlet />
          </div>
        </main>
      </div>
    );
  },
}); 

settingsRoute.addChildren([
    settingsApiKeysRoute
  ]); 