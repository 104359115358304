import { WireframeEditor } from "@/components/WireframeEditor";
import { useMemo, useState, useEffect } from "react";
import { useResource } from "../state";
import { getResourceOutput } from "@/lib/resource-utils";

function closeOpenElements(input: string) {
  const tokens = [...input.matchAll(/(?<popen>\()|(?<pclose>\))|(?<bopen>\{)|(?<bclose>\})|(?<tago>\<[^>/]*?\>)|(?<tagc>\<\/[^>]*?>)/g)].flatMap(o =>
    Object.entries(o.groups)
      .filter(([k, v]) => v)
      .map(([k, v]) => `${k}-${v}`),
  );

  const openTokensStack = [] as (
    | {
        type: "brackets" | "parents";
      }
    | { type: "parents" }
    | { type: "tag"; tagName: string }
  )[];

  const extractTagName = (t: string) =>
    t
      .split("-")[1]
      .split(" ")[0]
      .replace(/[<>\/]/g, "");
  for (const t of tokens) {
    const lastToken = openTokensStack[openTokensStack.length - 1];
    if (t.startsWith("tago")) {
      openTokensStack.push({ type: "tag", tagName: extractTagName(t) });
    } else if (t.startsWith("tagc")) {
      const tagName = extractTagName(t);
      if (lastToken.type === "tag" && lastToken.tagName === tagName) {
        openTokensStack.pop();
      }
    } else if (t.startsWith("bopen")) {
      openTokensStack.push({ type: "brackets" });
    } else if (t.startsWith("bclose")) {
      if (lastToken.type === "brackets") {
        openTokensStack.pop();
      }
    } else if (t.startsWith("popen")) {
      openTokensStack.push({ type: "parents" });
    } else if (t.startsWith("pclose")) {
      if (lastToken.type === "parents") {
        openTokensStack.pop();
      }
    }
  }
  let result = input;
  // Close all remaining elements
  while (true) {
    const token = openTokensStack.pop();
    if (!token) {
      break;
    }
    if (token.type === "tag") {
      result += `</${token.tagName}>`;
    } else if (token.type === "brackets") {
      result += "}";
    } else if (token.type === "parents") {
      result += ")";
    }
  }
  return result;
}

export const WireframePanel: React.FC<{ resourceRef: string }> = ({ resourceRef }) => {
  const source = useResource(resourceRef);
  const generatedCode = useMemo(() => getResourceOutput(source), [source]);
  const [code, setCode] = useState(generatedCode ?? "");
  const [editMode, setEditMode] = useState(false);
  useEffect(() => {
    if (source.status === "generating") {
      setEditMode(false);
    }
  }, [source.status]);
  const isGenerating = source.status === "generating";
  const codeToUse = editMode ? code : ((isGenerating && generatedCode ? closeOpenElements(generatedCode) : generatedCode) ?? "");

  return (
    <WireframeEditor
      code={codeToUse}
      onCodeChange={data => {
        if (source.status !== "generating") {
          setEditMode(true);
          setCode(data);
        }
      }}
    />
  );
};
