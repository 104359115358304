import { cn } from "@/lib/utils";

interface LogoProps {
  className?: string;
}

export function Logo({ className }: LogoProps) {
  return (
    <img 
      src="/logo.png"
      alt="Livecycle AI"
      className={cn("h-6 w-auto", className)}
    />
  );
}